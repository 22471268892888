<template>
  <!--begin::Card-->
  <div class="card card-flush mb-8" style="position: relative;">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2>Invoice</h2>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0 fs-6">
      <!--begin::Seperator-->
      <div class="separator separator-dashed mb-7"></div>
      <!--end::Seperator-->

      <!--begin::Section-->
      <div class="mb-0">
        <template v-if="order.invoices.length">
          <h5 class="mb-4">
            Invoice No. {{ order.invoices[0].numberFormatted }}<br>
            <small>
              <div v-if="order.paymentMethod == 'BANK_TRANSFER'">
                <i>Payment by Bank Transfer, ref. "Neoris Order {{ order.number }}"</i>
              </div>
              <div v-else>
                <span v-if="order.invoices[0].paymentCompleted">✅</span>
                <span v-else-if="order.invoices[0].paymentRejected">⛔️</span>
                <span v-else>⏳</span>
                Mollie Payment #{{ order.invoices[0].molliePaymentId }}
              </div>
            </small>
          </h5>
          <p class="text-muted">
            This invoice has been automatically generated when the payment has been:
            <ul>
              <li>Received (credit card)</li>
              <li>Authorized (SEPA direct debit)</li>
              <li>Requested (Manual Bank Transfer)</li>
            </ul>
          </p>
          <div class="mb-0 d-grid gap-2">
            <div
              class="btn btn-light"
              @click.prevent="downloadInvoice"
            >
              Download invoice
            </div>
          </div>
        </template>

        <p v-else class="fw-bold text-gray-600 mb-2">
           The invoice will automatically be generated once the payment is:
            <ul>
              <li>Received (credit card)</li>
              <li>Authorized (SEPA direct debit)</li>
            </ul>
        </p>
      </div>
      <!--end::Section-->

    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ["order-updated"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {},
  methods: {
    downloadInvoice() {
      ApiService.query(`invoices/${this.order.invoices[0].id}/pdf`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      }).then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.order.invoices[0].numberFormatted}-neoris-invoice.pdf`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  border: 1px solid #dddee4 !important;
}
</style>
