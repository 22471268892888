<template>
  <!--begin::Card-->
  <div class="card card-flush pt-3 mb-5 mb-xl-10">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2 class="fw-bolder me-6">Order Details</h2>
        <span :class="`badge badge-light-${orderStatusToColorClass(order.status)}`">
          {{ orderStatusToStr(order.status) }}
        </span>
        <!-- <a @click="$emit('edit')" class="btn btn-light btn-sm"
          ><i class="bi bi-pencil-square me-1 mb-1"></i>Edit</a
        > -->
      </div>
      <!--begin::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-3">
      <!--begin::Overview Section-->
      <div class="mb-0">
        <!--begin::Title-->
        <h5 class="mb-4">Clinic:</h5>
        <!--end::Title-->
        <!--begin::Patient-->
        <div class="d-flex flex-wrap py-5">
          <!--begin::Column-->
          <div class="flex-equal me-5">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Name:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.clinic.name }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Contact phone:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.clinic.phone }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Email:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.clinic.email }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Patient-->
      </div>
      <!--end::Overview Section-->
      <div class="separator mb-10 mt-4"></div>
      <!--begin::Overview Section-->
      <div class="mb-10">
        <!--begin::Title-->
        <h5 class="mb-4">Shipping address:</h5>
        <!--end::Title-->
        <!--begin::Patient-->
        <div class="d-flex flex-wrap py-5">
          <!--begin::Column-->
          <div class="flex-equal me-5">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Street:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.shippingAddress.street }}
                  <template v-if="order.shippingAddress.street2">
                    <br>{{ order.shippingAddress.street2 }}
                  </template>
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">City:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.shippingAddress.city }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Country:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.shippingAddress.country }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">Zip:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.shippingAddress.zip }}
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">State:</td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.shippingAddress.state }}
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Patient-->
      </div>
      <!--end::Overview Section-->
      <div class="separator mb-12"></div>
      <!--begin::Products Section-->
      <div class="mb-10">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-4">Products:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Patient-->
        <!--begin::Product table-->
        <div class="table-responsive">
          <!--begin::Table-->
          <table class="table align-middle table-row-dashed fs-6 gy-4 mb-0">
            <!--begin::Table head-->
            <thead>
              <!--begin::Table row-->
              <tr
                class="
                  border-bottom border-gray-200
                  text-start text-gray-400
                  fw-bolder
                  fs-7
                  text-uppercase
                  gs-0
                "
              >
                <th class="min-w-150px">Type</th>
                <th class="min-w-150px">Product</th>
                <th class="min-w-125px">Qty</th>
                <th class="min-w-125px">Per unit</th>
                <th class="min-w-125px">Stock sufficient</th>
              </tr>
              <!--end::Table row-->
            </thead>
            <!--end::Table head-->

            <!--begin::Table body-->
            <tbody class="fw-bold text-gray-800">
              <tr v-for="(product, idx) in order.products" :key="idx">
                <td>
                  <label class="w-150px">{{ product.product.category }}</label>
                </td>
                <td>
                  <label class="w-150px">{{ product.product.name }}</label>
                  <div class="fw-normal text-gray-600">
                    {{ product.product.reference }}
                  </div>
                </td>
                <td>
                  <label class="w-150px">{{ product.quantity }}</label>
                </td>
                <td>
                  <label class="w-150px">{{ product.product.price.toFixed(2) }} €</label>
                </td>
                <td>
                  <span
                    v-if="isStockSufficient(product)"
                    class="badge badge-light-success bg-light-success"
                  >
                    <i class="bi-check-lg text-success"></i>
                  </span>
                  <span v-else class="badge badge-light-danger bg-light-danger">
                    <i class="bi-x-lg text-danger"></i>
                  </span>
                </td>
              </tr>
            </tbody>
            <!--end::Table body-->
          </table>
          <!--end::Table-->
        </div>
        <!--end::Product table-->
      </div>
      <!--end::Products Section-->
      <div class="separator mb-10 mt-4"></div>
      <!--begin::Patient Section-->
      <div v-if="order" class="mb-10">
        <div
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-between
            mb-4
          "
        >
          <!--begin::Title-->
          <h5 class="mb-0">Total:</h5>
          <!--end::Title-->
        </div>

        <!--begin::Patient-->
        <div class="d-flex flex-wrap pt-5">
          <!--begin::Column-->
          <div class="flex-equal me-">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">
                  Price excl. VAT:
                </td>
                <td class="text-gray-800 min-w-200px">
                  {{ order.total.toFixed(2) }} €
                </td>
              </tr>
              <!--end::Row-->
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400 min-w-175px w-175px">
                  Price incl. VAT:
                </td>
                <td class="text-gray-800 min-w-200px">
                  {{
                    (order.total + order.total * (order.vat / 100)).toFixed(2)
                  }}
                  €
                </td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->

          <!--begin::Column-->
          <div class="flex-equal">
            <!--begin::Details-->
            <table class="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
              <!--begin::Row-->
              <tr>
                <td class="text-gray-400">VAT:</td>
                <td class="text-gray-800">{{ order.vat }}%</td>
              </tr>
              <!--end::Row-->
            </table>
            <!--end::Details-->
          </div>
          <!--end::Column-->
        </div>
        <!--end::Patient-->
      </div>
      <!--end::Patient Section-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import UserMenu from "@/layout/header/partials/UserMenu.vue";
import ApiService from "@/core/services/ApiService";

import OrderStatus from "@/core/constants/OrderStatus.ts";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stocks: [],
    };
  },
  created() {
    ApiService.query("/stocks", { params: { limit: 0 } }).then(({ data }) => {
      this.stocks = data.results;
    });
  },
  computed: {},
  methods: {
    toggleEditDetails() {
      this.isEditingDetails = !this.isEditingDetails;
    },
    isStockSufficient(productItem) {
      return productItem.quantity <= productItem.product.stock;
    },
    orderStatusToColorClass(status) {
      return OrderStatus[status].colorClass;
    },
    orderStatusToStr(status) {
      return OrderStatus[status].label;
    },
  },
};
</script>

<style lang="scss" scoped>
.operation-type {
  font-size: 0.9rem;
}
</style>
