<template>
  <!--begin::Layout-->
  <div v-if="order" class="d-flex flex-column flex-lg-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
      <Details :order="order" @edit="openEditDetails"></Details>
    </div>
    <!--end::Content-->

    <!--begin::Sidebar-->
    <div
      class="
        flex-column flex-lg-row-auto
        w-lg-250px w-xl-300px
        mb-10
        order-1 order-lg-2
      "
    >
      <Summary :order="order" @order-updated="orderUpdated" />
      <Invoice :order="order" />
    </div>
    <!--end::Sidebar-->
    <!-- <NewOperation
      name="new-operation-modal"
      v-model="isNewOperationModalOpen"
      @cancel="closeEditDetails"
      @confirm="onOperationUpdated"
    >
      <template v-slot:title>Edit operation</template>
    </NewOperation> -->
  </div>
  <!--end::Layout-->
</template>

<script>
import Details from "@/components/admin-orders/Details.vue";
import Summary from "@/components/admin-orders/Summary.vue";
import Invoice from "@/components/admin-orders/Invoice.vue";
import { StickyComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import ApiService from "@/core/services/ApiService";

export default {
  components: {
    Details,
    Summary,
    Invoice,
  },
  data() {
    return {
      isEditingDetails: false,
      order: false,
      isNewOperationModalOpen: false,
    };
  },
  mounted() {
    this.fetchOrder();
    setCurrentPageBreadcrumbs("View Order", [{ label: "Orders", to: '/orders' }]);
    this.$nextTick(() => {
      StickyComponent.reInitialization();
    });
  },
  methods: {
    openEditDetails() {
      this.$vfm.show("new-operation-modal", this.operation);
    },
    closeEditDetails() {
      this.isNewOperationModalOpen = false;
    },
    onOperationUpdated(updatedOperation) {
      this.$vfm.hide("new-operation-modal");
      this.fetchOrder();
    },
    fetchOrder() {
      ApiService
        .get('/orders', this.$route.params.id)
        .then(({ data }) => {
          console.log(data);
          this.order = data;
        })
        .catch((err) => {
          // this.isTableLoading = false;
        });
    },
    orderUpdated(order) {
      this.order = order;
    },
  },
};
</script>
