<template>
  <!--begin::Card-->
  <div class="card card-flush mb-8" style="position: relative;">
    <!--begin::Card header-->
    <div class="card-header">
      <!--begin::Card title-->
      <div class="card-title">
        <h2>Summary</h2>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0 fs-6">
      <!--begin::Seperator-->
      <div class="separator separator-dashed mb-7"></div>
      <!--end::Seperator-->

      <!--begin::Section-->
      <div class="mb-7">
        <!--begin::Clinic-->
        <h5 class="mb-4">
          {{ order.clinic.name }}
        </h5>
        <!--end::Clinic-->

        <!--begin::Price-->
        <p class="fw-bold mb-2">
          Order amount: <span class="text-gray-600">{{ order.total.toFixed(2) }} €</span>
        </p>
        <!--end::Price-->

        <!--begin::Tracking number-->
        <p
          v-if="order.trackingNumber"
          class="fw-bold mb-2"
        >
          Tracking number: <span class="text-gray-600">{{ order.trackingNumber }}</span>
        </p>
        <!--end::Tracking number-->

        <!--begin::Status-->
        <p class="fw-bold text-gray-600 mb-2">
          <span
            class="badge"
            :class="`badge-light-${orderStatusToColorClass(order.status)}`"
            >{{ orderStatusToStr(order.status) }}</span
          >
        </p>
        <!--end::Status-->

        <!--end::Details-->
      </div>
      <!--end::Section-->

      <!--begin::Seperator-->
      <div class="separator separator-dashed mb-7"></div>
      <!--end::Seperator-->

      <!--begin::Section-->
      <div class="mb-4">
        <!--begin::Title-->
        <h5 class="mb-4">Actions</h5>
        <!--end::Title-->

        <!--begin::Details-->
        <div class="mb-0 d-grid gap-2">
          <a
            class="btn btn-light"
            @click.prevent="updateStatus"
            v-if="hasNextStatus()"
          >
            Update to {{ getNextOrderStatusLabel() }}
            <small v-if="getNextOrderStatus() == 'IN_PROCESS'">
              <br>(⚠️ will destock products)
            </small>
          </a>
          <p v-else-if="order.status == 'PENDING_PAYMENT_RECEIVED'" class="text-muted">
            You will be able to handle this order once the payment has been received (credit card).
          </p>
          <p v-else class="text-muted">
            No action available on this order.
          </p>
        </div>
        <!--end::Details-->
      </div>
      <!--end::Section-->

      <!--begin::Seperator-->
      <div v-if="hasNextStatus()" class="separator separator-dashed mb-4"></div>
      <!--end::Seperator-->

      <!--begin::Actions-->
      <!-- <div class="mb-0 d-grid">
        <a @click="cancelOrder" class="btn btn-light-danger"> Cancel order </a>
      </div> -->
      <!--end::Actions-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
</template>

<script>
import OrderStatus from "@/core/constants/OrderStatus";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  emits: ["order-updated"],
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {},
  methods: {
    cancelOrder() {
      ApiService.update("orders", this.order.id, { status: "CANCELLED" }).then(
        ({ data }) => {
          this.$emit("order-updated", data);
          this.toast.success("Order cancelled");
        }
      );
    },
    updateStatus() {
      const status = this.getNextOrderStatus();
      const orderData = { status };
      if (status == "SHIPPED") {
        const trackingNumber = prompt("If you have a tracking number, please enter it below:");
        if (trackingNumber) {
          orderData.trackingNumber = trackingNumber;
        }
      }

      ApiService.update("orders", this.order.id, orderData).then(
        ({ data }) => {
          this.$emit("order-updated", data);
          this.toast.success("Order status updated");
        }
      );
    },
    orderStatusToColorClass(status) {
      return OrderStatus[status].colorClass;
    },
    orderStatusToStr(status) {
      return OrderStatus[status].label;
    },
    hasNextStatus() {
      return !!OrderStatus[this.order.status].nextStatus;
    },
    getNextOrderStatus() {
      return OrderStatus[this.order.status].nextStatus;
    },
    getNextOrderStatusLabel() {
      return OrderStatus[this.getNextOrderStatus()].label;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  border: 1px solid #dddee4 !important;
}
</style>
